<template>
    <v-section-group>
        <template v-if="isLoading">
            <div class="w-full flex items-center justify-center">
                <v-loader />
            </div>
        </template>

        <template v-else>
            <div class="grid grid-cols-1 divide-y-2 divide-default">
                <template v-for="(statistic, statisticIndex) in statistics">
                    <div :key="'statistic_' + statisticIndex">
                        <v-card-content>
                            <div>
                                <div class="text-sm text-gray-400 font-medium truncate">
                                    {{ statistic.label }}
                                </div>

                                <v-text class="mt-1 text-2xl font-semibold">
                                    {{ statistic.value | numberFormat }}
                                </v-text>
                            </div>
                        </v-card-content>
                    </div>
                </template>
            </div>
        </template>
    </v-section-group>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import PayoutService from "@/services/modules/payout-service";

export default defineComponent({
    data() {
        return {
            statistics: [],
        };
    },
    created() {
        this.toggleLoading();

        Promise.all([
            PayoutService.stats().pending().getAdminFeeTotal(),
            PayoutService.stats().pending().getFastPassTotal(),
            PayoutService.stats().pending().getSherpaTotal(),
            PayoutService.stats().pending().getSystemTotal(),
        ])
            .then((values) => {
                const stats = values.map(({ data }) => data);
                this.statistics = stats;
            })
            .finally(() => {
                this.toggleLoading();
            });
    },
});
</script>
