<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section>
                <v-section-heading>
                    Insights
                </v-section-heading>

                <v-payout-stats />
            </v-section>
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-section-heading>
                <template v-slot:default>
                    Generate Payouts
                </template>

                <template v-slot:description>
                    Select the jobs you want to generate payouts and click the 'Generate Payouts' button.
                </template>

                <template v-slot:actions>
                    <div class="w-full grid grid-cols-2 sm:grid-cols-1 gap-2 sm:place-items-end">
                        <div class="block lg:hidden">
                            <v-button class="w-full" color="primary" @click.stop="toggleSidebar">
                                <i class="fa fa-chart-bar mr-2" />
                                View Insights
                            </v-button>
                        </div>

                        <div>
                            <v-button class="w-full sm:w-auto" :disabled="isLoading || !$me.hasPermission('payouts.create') || !Object.values(payoutsForm.order_line_user_ids).length || !isPayoutAllowed" @click="submitPayouts()">
                                Generate Payouts
                            </v-button>
                        </div>
                    </div>
                </template>
            </v-section-heading>

            <v-table-container>
                <v-table>
                    <template v-if="isLoading">
                        <v-table-head>
                            <v-table-header>Loading, please wait.</v-table-header>
                        </v-table-head>

                        <v-table-body>
                            <v-table-row>
                                <v-table-data align="center" colspan="100%">
                                    <v-loader />
                                </v-table-data>
                            </v-table-row>
                        </v-table-body>
                    </template>

                    <template v-else>
                        <v-table-head>
                            <v-table-header :full-width="false">
                                <div class="flex space-x-4">
                                    <v-form-checkbox :checked="allOptionsSelected" @change="toggleAllSelectedPivotData" />
                                    <div>Job ID</div>
                                </div>
                            </v-table-header>

                            <v-table-header>
                                Product
                            </v-table-header>

                            <v-table-header>
                                Relationship
                            </v-table-header>

                            <v-table-header class="text-right">
                                Source Price
                            </v-table-header>

                            <v-table-header class="text-right">
                                Payout Amount
                            </v-table-header>
                        </v-table-head>

                        <v-table-body v-for="(pivotDataLine, pivotDataLineIndex) in parsedPivotData" :key="'pivotData_' + pivotDataLineIndex">
                            <v-table-row>
                                <v-table-data colspan="100%">
                                    <span class="text-gray-300 capitalize">
                                        {{ pivotDataLine.user_name }}
                                    </span>
                                </v-table-data>
                            </v-table-row>

                            <v-table-row v-for="(orderLine, orderLineIndex) in pivotDataLine.order_lines" :key="'orderLine_' + orderLineIndex">
                                <v-table-data :full-width="false">
                                    <div class="flex space-x-4">
                                        <v-form-checkbox v-model="payoutsForm.order_line_user_ids" :value="orderLine.order_line_user_id" />
                                        <v-link color="primary" @click.stop="selectOrderLine(orderLine.order_line_id)">
                                            {{ orderLine.order_line_id }}
                                        </v-link>
                                    </div>
                                </v-table-data>

                                <v-table-data>
                                    <span class="text-gray-300">
                                        {{ orderLine.product_name }}
                                    </span>
                                </v-table-data>

                                <v-table-data>
                                    <span class="text-gray-300">
                                        {{ orderLine.order_line_user_relationship_type_name }}
                                    </span>
                                </v-table-data>

                                <v-table-data align="right">
                                    <span class="text-gray-300">
                                        {{ orderLine.order_line_price_source | numberFormat }}
                                    </span>
                                </v-table-data>

                                <v-table-data align="right">
                                    <span class="text-gray-300">
                                        {{ orderLine.order_line_payout_amount | numberFormat }}
                                    </span>
                                </v-table-data>
                            </v-table-row>

                            <v-table-row>
                                <v-table-data align="right" colspan="100%">
                                    <span class="text-secondary-500 font-semibold">
                                        {{ pivotDataLine.user_total_payout | numberFormat }}
                                    </span>
                                </v-table-data>
                            </v-table-row>
                        </v-table-body>
                    </template>
                </v-table>
            </v-table-container>

            <v-card-footer>
                <nav class="flex items-center justify-between">
                    <div class="hidden sm:block">
                        <template v-if="!isLoading && items.meta">
                            <v-text>
                                Showing
                                {{ ' ' }}
                                <span class="font-medium">{{ items.meta.from }}</span>
                                {{ ' ' }}
                                to
                                {{ ' ' }}
                                <span class="font-medium">{{ (items.meta.current_page * 15) > items.meta.total ? items.meta.total : items.meta.current_page * 15 }}</span>
                                {{ ' ' }}
                                of
                                {{ ' ' }}
                                <span class="font-medium">{{ items.meta.total }}</span>
                                {{ ' ' }}
                                results
                            </v-text>
                        </template>

                        <template v-else>
                            <v-text>
                                Loading, please wait.
                            </v-text>
                        </template>
                    </div>

                    <template v-if="items.meta">
                        <div class="flex-1 flex justify-between sm:justify-end space-x-default">
                            <v-button color="primary" :disabled="page <= 1" @click.stop="page = parseInt(page) - 1">
                                Previous
                            </v-button>

                            <v-button color="primary" :disabled="page >= items.meta.last_page" @click.stop="page = parseInt(page) + 1">
                                Next
                            </v-button>
                        </div>
                    </template>
                </nav>
            </v-card-footer>

            <v-job-slide-over
                v-if="selectedOrderLineId"
                v-model="jobSlideOver"
                :job-id="selectedOrderLineId"
            />
        </template>
    </v-main>
</template>

<script>
import _ from "lodash";
import Collect from "collect.js";
import JobSlideOver from "@/components/application/job/job-slide-over.vue";
import PayoutService from "@/services/modules/payout-service";
import PayoutStats from "@/components/application/payout/payout-stats.vue";

export default {
    components: {
        "v-payout-stats": PayoutStats,
        "v-job-slide-over": JobSlideOver,
    },
    data() {
        return {
            /**
             * @todo Write a description.
             */
            isPayoutAllowed: (process.env.VUE_APP_PAYOUT_ALLOWED === "true"),

            /**
             * @todo Write a description.
             */
            pivotData: [],

            /**
             * @todo Write a description.
             */
            payoutsForm: {
                order_line_user_ids: [],
            },

            /**
             * @todo Write a description.
             */
            selectedOrderLineId: null,

            /**
             * @todo Write a description.
             */
            jobSlideOver: false,

            items: {},
            page: this.$route.query.page ?? 1,
        };
    },
    computed: {
        /**
         * @todo Write a description.
         */
        parsedPivotData() {
            return _.sortBy(Object.values(Collect(this.pivotData)
                /**
                 * Format the order line users.
                 */
                .map((orderLineUser) => ({
                    order_line_user_id: orderLineUser.order_line_user_id,
                    order_line_id: orderLineUser.order_line_id,
                    order_line_price_source: orderLineUser.order_line.price_source,
                    order_line_payout_amount: orderLineUser.payout_amount,
                    order_line_user_relationship_type_id: orderLineUser.relationship_type.order_line_user_relationship_type_id,
                    order_line_user_relationship_type_name: orderLineUser.relationship_type.name,
                    product_id: orderLineUser.order_line.product.product_id,
                    product_name: orderLineUser.order_line.product.name,
                    user_id: orderLineUser.user_id,
                    user_name: String(orderLineUser.user_name).toLowerCase(),
                }))

                /**
                 * Sort the results by the user's names.
                 */
                .sortBy("user_name")

                /**
                 * Use reduce to group the collection.
                 */
                .reduce((carry, item) => {
                    if (!(item.user_id in carry)) {
                        carry[item.user_id] = {
                            user_id: item.user_id,
                            user_name: String(item.user_name).toLowerCase(),
                            user_total_payout: item.order_line_payout_amount,
                            order_lines: [item],
                        };
                    } else {
                        carry[item.user_id].user_total_payout = parseFloat(carry[item.user_id].user_total_payout) + parseFloat(item.order_line_payout_amount);
                        carry[item.user_id].order_lines.push(item);
                    }

                    return carry;
                }, {})), ["user_name"]);
        },

        /**
         * @todo Write a description.
         */
        availableSelectionIds() {
            return Collect(this.pivotData).map(({ order_line_user_id: orderLineUserId }) => orderLineUserId).toArray();
        },

        /**
         * @todo Write a description.
         */
        allOptionsSelected() {
            return (Object.values(this.availableSelectionIds).length === Object.values(this.payoutsForm.order_line_user_ids).length);
        },

        /**
         * @todo Write a description.
         */
        selectedGame() {
            const result = this.$store.getters["appModule/getSettings"].selectedGame;

            if (result === "null") {
                return null;
            }

            return result;
        },
    },
    watch: {
        /**
         * @todo Write a description.
         */
        selectedGame: {
            handler() {
                if (!this.isLoading) this.toggleLoading();

                this.fetchPivotData()
                    .finally(() => {
                        this.toggleAllSelectedPivotData();

                        if (this.isLoading) this.toggleLoading();
                    });
            },
            immediate: true,
        },

        /**
         * @todo Write a description.
         */
        "$route.query": {
            handler({ jobId, jobSlideOver }) {
                if (!jobId || !jobSlideOver) {
                    this.selectedOrderLineId = null;
                    this.jobSlideOver = false;
                    return;
                }

                this.selectedOrderLineId = jobId;
                this.jobSlideOver = true;
            },
            immediate: true,
            deep: true,
        },

        /**
         * @todo Write a description.
         */
        jobSlideOver: {
            handler(value) {
                if (!value) {
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            jobId: undefined,
                            jobSlideOver: undefined,
                        },
                    });
                }
            },
        },

        /**
         * @todo Write a description.
         */
        page: {
            handler(value) {
                if (value) {
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            page: value,
                        },
                    });
                }
                if (!this.isLoading) this.toggleLoading();
                this.fetchPivotData()
                    .finally(() => {
                        this.toggleAllSelectedPivotData();
                        if (this.isLoading) this.toggleLoading();
                    });
            },
        },
    },
    methods: {
        /**
         * @todo Write a description.
         */
        fetchPivotData() {
            return new Promise((resolve, reject) => {
                PayoutService.getCompletedJobReadyForPayout({ page: this.page })
                    .then((response) => {
                        this.pivotData = response.data.data;
                        this.items = response.data;
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        /**
         * @todo Write a description.
         */
        submitPayouts() {
            this.toggleLoading();

            this.$store.dispatch("payoutModule/storeMultiple", this.payoutsForm)
                .then(() => {
                    this.$router.push({ name: "payouts.index" });
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },

        /**
         * @todo Write a description.
         */
        toggleAllSelectedPivotData() {
            if (this.allOptionsSelected) {
                this.payoutsForm.order_line_user_ids = [];
            } else {
                this.payoutsForm.order_line_user_ids = this.availableSelectionIds;
            }
        },

        /**
         * @todo Write a description.
         */
        selectOrderLine(orderLineId) {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    jobId: orderLineId || undefined,
                    jobSlideOver: orderLineId ? true : undefined,
                },
            });
        },
    },
};
</script>
